import PropTypes from "prop-types"
import React from "react"
import GridContainer from "../layout/GridContainer/GridContainer"

// Import necessary Bulma CSS Framework components.
import "./HTMLWrapper.library.scss"

// Import component specific styles.
import styles from "./HTMLWrapper.module.scss"

const HTMLWrapper = props => (
  <GridContainer>
    <div className={styles.HTMLWrapper}
      dangerouslySetInnerHTML={{
        __html: props.text,
      }}
    />
  </GridContainer>
)

HTMLWrapper.propTypes = {
  text: PropTypes.string,
}

HTMLWrapper.defaultProps = {
  text: ``,
}

export default HTMLWrapper
