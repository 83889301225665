import React from "react"
import { graphql } from "gatsby"
import HuntWalkIntro from "../components/HuntWalkIntro/HuntWalkIntro"
import { FaMapMarkedAlt } from "react-icons/fa"
import HuntWalkHeader from "../components/HuntWalkHeader/HuntWalkHeader"
import Layout from "../components/layout/Layout/Layout"

// Todo Add listing data for content types other than Pages
export const query = graphql`
  query($slug: String!) {
    datoCmsJourney(slug: { eq: $slug }) {
      introductoryText
      facebookLink
      title
      backgroundImage {
        url
        alt
      }
      facebookText
      slug
      journeyCategory {
        name
      }
      seo {
        image {
          url
        }
        description
        title
        twitterCard
      }
    }
  }
`

const JourneyIntro = props => {
  const isHunt = props.data.datoCmsJourney.journeyCategory.name === "Hunt" ? true : false

  return (
    <Layout
      removeNav={true}
      metaTitle={props.data.datoCmsJourney.seo ? props.data.datoCmsJourney.seo.title : props.data.datoCmsJourney.title}
      metaDescription={props.data.datoCmsJourney.seo ? props.data.datoCmsJourney.seo.description : null}
      metaImage={
        props.data.datoCmsJourney.seo && props.data.datoCmsJourney.seo.image ? props.data.datoCmsJourney.seo.image.url : null
      }
      twitterCard={
        props.data.datoCmsJourney.seo && props.data.datoCmsJourney.seo.twitterCard
          ? props.data.datoCmsJourney.seo.twitterCard
          : null
      }
      pathname={props.uri}
    >
      <HuntWalkHeader />
      <HuntWalkIntro
        slug={props.data.datoCmsJourney.slug}
        isHunt={isHunt}
        backgroundImageSrc={props.data.datoCmsJourney.backgroundImage.url}
        backgroundImageAlt={props.data.datoCmsJourney.backgroundImage.alt}
        title={props.data.datoCmsJourney.title}
        description={props.data.datoCmsJourney.introductoryText}
        mapIcon={<FaMapMarkedAlt />}
        mapText='View Map'
        mapLink={props.data.datoCmsJourney.slug + '/map'}
        huntText='Remember, if you find anything you cannot identify, send a Facebook message with a photo to Marine Ecology Selsey and get an asnwer from an expert.'
        socialMediaText={props.data.datoCmsJourney.facebookText}
        socialMediaLink={props.data.datoCmsJourney.facebookLink}
        link={props.data.datoCmsJourney.slug + '/step/1'}
        linkText='BEGIN!'
      />
    </Layout>
  )
}

export default JourneyIntro
