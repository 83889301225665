import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import GridContainer from "../layout/GridContainer/GridContainer"
import Logo from '../../images/ds_logo_crop.svg';
import Facebook from "../../images/facebook-logo.svg"

// Import necessary Bulma CSS Framework components.
import "./HuntWalkHeader.library.scss"

// Import component specific styles.
import styles from "./HuntWalkHeader.module.scss"

const logo = {
  src: Logo,
  alt: 'Destination Selsey Logo',
};

const FacebookLogo = {
  src: Facebook,
  alt: 'Facebook Logo',
};

const HuntWalkHeader = props => (
  <header>
    <GridContainer>
      <div className={props.secretPage && props.secretPage === true ? styles.huntWalkHeader + ' ' + styles.secretPage : styles.huntWalkHeader}>
        <div className={styles.firstHeaderWrapper}>
          { props.previousPage && (
            <Link to={props.previousPage} aria-label="Previous page" className={styles.backButtonLink}>
            <div className={styles.backButton}>
              {props.backButton}
            </div>
          </Link>
          ) }
          { props.stepNumber && props.totalSteps && (
            <div className={styles.stepNumber}>
              <p className="listTimeText">{props.stepNumber}/{props.totalSteps}</p>
            </div>
          )}
        </div>
        <div className={styles.secondHeaderWrapper}>
          { logo.src && logo.alt && (
            <div className={styles.logo}>
              <Link to="/" aria-label="Navigate to the Home page"><img src={logo.src} alt={logo.alt} /></Link>
            </div>
          )}
        </div>
        <div className={styles.thirdHeaderWrapper}>
          { props.socialMediaLink && (
            <div className={styles.socialMedia}>
              <a href={props.socialMediaLink} target="_blank" rel="noreferrer" aria-label="Share on facebook"><img src={FacebookLogo.src} alt={FacebookLogo.alt}/></a>
            </div>
          )}
          { props.map && props.mapLink && (
            <div className={styles.map}>
              <Link
                aria-label="Map"
                to={props.mapLink}
                state={{ prevPath: props.currentPage }}
              >{props.map}</Link>
            </div>
          )}
          { props.nextPage && (
            <Link to={props.nextPage} aria-label="Next Step" className={styles.nextButtonLink}>
              <div className={styles.nextButton}>
                {props.nextButton}
              </div>
            </Link>
          )}
        </div>
      </div>
    </GridContainer>
  </header>
)

HuntWalkHeader.propTypes = {
  currentPage: PropTypes.string,
  previousPage: PropTypes.string,
  backButton: PropTypes.object,
  stepNumber: PropTypes.number,
  totalSteps: PropTypes.number,
  mapLink: PropTypes.string,
  nextPage: PropTypes.string,
  nextButton: PropTypes.object,
  socialMediaLink: PropTypes.string,
  map: PropTypes.object,
  secretPage: PropTypes.bool,
}

HuntWalkHeader.defaultProps = {
  currentPage: ``,
  previousPage: null,
  backButton: null,
  stepNumber: ``,
  totalSteps: ``,
  mapLink: ``,
  nextPage: null,
  nextButton: null,
  socialMediaLink: ``,
  map: ``,
  secretPage: false,
}

export default HuntWalkHeader
