import PropTypes from "prop-types"
import React from "react"
import { Link } from "gatsby"
import Facebook from "../../images/facebook-logo.svg"
import HTMLWrapper from "../HTMLWrapper/HTMLWrapper"

// Import necessary Bulma CSS Framework components.
import "./HuntWalkIntro.library.scss"

// Import component specific styles.
import styles from "./HuntWalkIntro.module.scss"

const FacebookLogo = {
  src: Facebook,
  alt: 'Facebook Logo',
};

const HuntWalkIntro = props => (
  <div className={styles.huntWalkIntroWrapper}>
    <div className={styles.backgroundImage}>
      <img src={props.backgroundImageSrc} alt={props.backgroundImageAlt} />
    </div>
    <div className={styles.backgroundOverlay}></div>
      <div className={styles.huntWalkIntro}>
          <div className={styles.textWrapper}>
              <h1 className={styles.title}>{props.title}</h1>
              <div className={styles.description}>
                <HTMLWrapper text={props.description}></HTMLWrapper>
              </div>
              <div className={styles.mapLinks}>
                <Link
                  to={props.mapLink}
                  state={{ prevPath: props.slug }}
                  aria-label={'View map for ' + props.title}
                >
                {props.mapIcon}
                <p className={styles.mapText + " linkDarkSmall"}>{props.mapText}</p>
                </Link>
              </div>
              <div className={styles.huntExtra}>
                { props.isHunt === true && props.socialMediaLink
                  ? <div className={styles.wrapper}>
                      <p className={styles.huntText}>{props.huntText}</p>
                      <a className={styles.socialMedia} href={props.socialMediaLink} target="_blank" rel="noreferrer" aria-label={"Visit " + props.socialMediaText + " Facebook page"}>
                        <img src={FacebookLogo.src} alt={FacebookLogo.alt}></img>
                        <p className={styles.socialMediaText + " linkDarkSmall"}>{props.socialMediaText}</p>
                      </a>
                    </div>
                  : ''
                }
              </div>
            </div>
          <div className={styles.cta}>
            <Link to={props.link} aria-label={'Begin ' + props.title}>{props.linkText}</Link>
          </div>
      </div>
  </div>
)

HuntWalkIntro.propTypes = {
  currentPage: PropTypes.string,
  isHunt: PropTypes.bool,
  backgroundImageSrc: PropTypes.string,
  backgroundImageAlt: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  mapIcon: PropTypes.object,
  mapText: PropTypes.string,
  mapLink: PropTypes.string,
  huntText: PropTypes.string,
  socialMediaText: PropTypes.string,
  socialMediaLink: PropTypes.string,
  link: PropTypes.string,
  linkText: PropTypes.string,
}

HuntWalkIntro.defaultProps = {
  currentPage: ``,
  isHunt: false,
  backgroundImageSrc: ``,
  backgroundImageAlt: ``,
  title: ``,
  description: ``,
  mapIcon: {},
  mapText: ``,
  mapLink: ``,
  huntText: ``,
  socialMediaText: ``,
  socialMediaLink: ``,
  link: ``,
  linkText: ``,
}

export default HuntWalkIntro
